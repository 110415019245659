<!-- <div id="preloader"></div> -->
<div class="up">
	<a href="#" class="scrollup text-center"><i class="fas fa-chevron-up"></i></a>
</div>

<!-- Start of header section
	============================================= -->
<header id="header_id" class="main_header header_style_four">
	<!-- <div class="header_top_content clearfix">
			<div class="container">
				<div class="header_top_text float-left">
					<span>Do you want to consult us? <a routerLink="contact">Find out more</a></span>
				</div>
				<div class="header_top_contact float-right">
					<a href="#"><i class="fas fa-phone"></i> 7403 111222</a>
					<a href="#"><i class="fas fa-map-marker-alt"></i> 27/12 J, 2nd Floor, A K S Tower ,  Calicut , Kerala </a>
					<a href="#"><i class="far fa-envelope"></i> info</a>
				</div>
			</div>
		</div> -->
	<div class="header_main_menu_wrap clearfix">
		<div class="container">
			<div class="brand_logo float-left">
				<a [routerLink]="['/home']">
					<img src="assets/images/theme-images/loanon-logo.png" alt="">
				</a>
			</div>
			<div class="rx_menu_item">
				<nav class="rx_main_navigation ul-li">
					<ul>
						<ul>
							<li><a class="nav-link" routerLink="/home">Home</a></li>
							<li><a class="nav-link" routerLink="/about">About</a></li>
							<li><a class="nav-link active" routerLink="/service">Service</a></li>
							<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
							<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
							<li><a class="nav-link" routerLink="/contact">Contact</a></li>
						</ul>
					</ul>
				</nav>
				<div class="rx_header_btn text-center text-uppercase float-right">
					<a [routerLink]="['/loan-app']">Loan App</a>
				</div>
			</div>
			<!-- desktop menu -->
			<div class="rx-mobile_menu position-relative">
				<div class="rx-mobile_menu_button rx-open_mobile_menu" (click)="toggleClass()">
					<i class="fas fa-bars"></i>
				</div>
				<div class="rx-mobile_menu_wrap" [ngClass]="{'mobile_menu_on': isClassActive}">
					<div class="mobile_menu_overlay rx-open_mobile_menu"></div>
					<div class="rx-mobile_menu_content">
						<div class="rx-mobile_menu_close rx-open_mobile_menu" (click)="toggleClass()">
							<i class="fas fa-times"></i>
						</div>
						<div class="m-brand-logo text-center">
							<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt=""></a>
						</div>
						<nav class="rx-mobile-main-navigation  clearfix ul-li">
							<ul id="m-main-nav" class="navbar-nav text-capitalize clearfix">
								<li><a class="nav-link active" routerLink="/home">Home</a></li>
								<li><a class="nav-link" routerLink="/about">About</a></li>
								<li><a class="nav-link" routerLink="/service">Service</a></li>
								<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
								<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
								<li><a class="nav-link" routerLink="/contact">Contact</a></li>
							</ul>
						</nav>
					</div>
				</div>
				<!-- /Mobile-Menu -->
			</div>
		</div>
	</div>
</header>
<!-- End of header section
	============================================= -->
<!-- <section class="saasio-breadcurmb-section">
	<div class="container">
		<div class="breadcurmb-title text-center">
			<h2>Services</h2>
		</div>
		<div class="breadcurmb-item-list text-center ul-li">
			<ul class="saasio-page-breadcurmb">
				  <li><a href="#">Home</a></li>  
				<li><a href="#">Services</a></li>
			</ul>
		</div>
	</div>
</section> -->
<!-- Start of practice area  section
	============================================= -->
<section class="pt-5 mt-5">
	<div class="container">

		<div class="rx-practice-content">
			<div class="row">
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="200ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Personal Loan</h3>

							<div class="rx-single">
								<ul>
									<li>Personal Loan</li>
									<li>Doctors</li>
									<li>Charter Accountant</li>
									<li>Small Ticket Loan</li>
									<li>Takeover lowest rate</li>
									<li>Top up</li>
								</ul>
							</div>

						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-user"></i>
						</div>
					</div>

				</div>
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="200ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Loan Against</h3>
							<div class="rx-single">
								<ul>
									<li>Commercial Building</li>
									<li>Residencial Property</li>
									<li>Plain Property</li>
									<li>Topup & Takeover Existing Loan</li>
									<li>Small ticket Lap</li>
									<li>Rental serrogate</li>
								</ul>
							</div>
						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-user "></i>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="0ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Business Loan</h3>
							<div class="rx-single">
								<ul>
									<li>Doctors</li>
									<!-- <li>SME Loan</li> -->
									<li>GST Loan</li>
									<li>Charterd Accountant</li>
									<li>GST Programe</li>
									<!-- <li>Income Programe</li> -->
									<li>Certificate Programe</li>
									<li>Small ticket loans</li>
								</ul>
							</div>

						</div>
						<div class="rx-practice-icon text-center">
							<i class="fa fa-briefcase" aria-hidden="true"></i>

						</div>
					</div>

				</div>
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="10ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Car Loan</h3>
							<div class="rx-single">
								<ul>
									<li>Used Car</li>
									<li>Refinance</li>
									<li>Topup</li>
									<li>Takeover (UP to 200%)</li>
								</ul>
							</div>

						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-car"></i>
						</div>
					</div>

				</div>
				
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="0ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Home Loan</h3>
							<div class="rx-single">
								<ul>
									<li>Home Construction</li>
									<li>Home Purchase</li>
									<li>Home Renovation</li>
									<li>Home Extension</li>
									<li>Takeover and top up</li>

								</ul>
							</div>

						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-home"></i>
						</div>
					</div>

				</div>
				<div class="col-lg-4 col-md-6 wow fadeFromUp" data-wow-delay="300ms" data-wow-duration="1000ms">
					<div class="rx-practice-icon-text position-relative">
						<div class="rx-practice-text headline">
							<h3>Other Products</h3>
							<div class="rx-single">
								<ul>
									<li>Credit Card</li>
									<li>Over Draft</li>
									<li>Education Loan</li>
									<li>Gold Loan</li>
								</ul>
							</div>
						</div>
						<div class="rx-practice-icon text-center">
							<i class="fas fa-credit-card"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- End of practice area section
	============================================= -->

<!-- Start of Footer section
	============================================= -->
<footer class="footer_section relative-position footer_section_4">
	<div class="background_overlay"></div>
	<div class="container">
		<div class="footer_content">
			<div class="row">
				<div class="col-lg-4 col-md-12">
					<div class="footer_widget headline">
						<h3 class="widget_title">
							About Us
						</h3>
						<div class="widget_footer_text">
							At Loan Onn, we pride ourselves on being your one-stop destination for all loans.
						</div>

					</div>
				</div>
				<div class="col-lg-4 col-md-12">
					<div class="footer_widget headline">
						<h3 class="widget_title">
							Usefull Link

						</h3>
						<div class="practice_list ul-li-block clearfix">
							<ul>
								<li><a class="nav-link active" routerLink="/home">Home</a></li>
								<li><a class="nav-link" routerLink="/about">About</a></li>
								<li><a class="nav-link" routerLink="/service">Service</a></li>
								<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
								<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
								<li><a class="nav-link" routerLink="/contact">Contact</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-12">
					<div class="footer_widget headline">
						<h3 class="widget_title">
							Contact Us
						</h3>
						<div>
							<ul>
								<li class="d-flex">
									<i class="fas fa-map-marker-alt mr-2"></i> 
									<a href="#"> Loanonn &#8482;<br> Infin Plus Pvt Ltd <br>
									27/12 J, 2nd Floor, A K S Tower
									Puthiyara Jn, Calicut
									Kerala – 673004 </a>
								</li>
								<li>
									<i class="fas fa-envelope mr-2"></i><a href="#">
										info&#64;investfinanceplus.com</a>
								</li>
								<li >
									<i class="fas fa-envelope mr-2"></i><a href="#">
										info&#64;loanonn.com</a>
								</li>
								<li>
									<i class="fas fa-phone mr-2"></i><a href="#">
										7403 111222</a>
								</li>
								
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>

<div class="footer_copyright">
	<div class="container">
		<div class="footer_copyright_content">
			<div class="row">
				<div class="col-lg-4 col-md-12">
					<div class="footer_social ul-li clearfix">
						<ul>
							<li><a href="https://www.facebook.com/infinsmartadvise/"><i
										class="fab fa-facebook-f"></i></a></li>
							<li><a href="#"><i class="fab fa-twitter"></i></a></li>
							<li><a href="https://www.instagram.com/infinsmartadvise/"><i
										class="fab fa-instagram"></i></a></li>
							
							
						</ul>
					</div>
				</div>
				<div class="col-lg-4 col-md-12">
					<div class="footer_logo text-center">
				<p class="text-white"><span>*</span>Terms & Conditions</p>
				<h6 class="text-white">The loans offered on the platform are subject to the terms and conditions and loan approval process of the  RBI-licensed BANKS AND NBFC partners</h6>
			</div>
			</div>
				<div class="col-lg-4 col-md-12">
					<div class="copyright_text text-right">
						Copyright By <a href="https://hexabranding.com/">Hexa Branding</a> - 2024
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- End of Footer section
	============================================= -->