
	<!-- <div id="preloader"></div> -->
	<div class="up">
		<a href="#" class="scrollup text-center"><i class="fas fa-chevron-up"></i></a>
	</div>

<!-- Start of header section
	============================================= -->
	<header id="header_id" class="main_header header_style_four">
		
		<div class="header_main_menu_wrap clearfix">
			<div class="container">
				<div class="brand_logo float-left">
					<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt="logo"></a>
				</div>
				<div class="rx_menu_item">
					<nav class="rx_main_navigation ul-li">
						<ul>
                            <ul>
                                <li><a class="nav-link" routerLink="/home">Home</a></li>
							<li><a class="nav-link " routerLink="about">About</a></li>
							<li><a class="nav-link" routerLink="/service">Service</a></li>
							<li><a class="nav-link active" routerLink="/rewards">Reward on loan</a></li>
							<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
							<li><a class="nav-link" routerLink="/contact">Contact</a></li>
                            </ul>
						</ul>
					</nav>
					<div class="rx_header_btn text-center text-uppercase float-right">
						<a [routerLink]="['/loan-app']">Loan App</a>
					</div>
				</div>
				<!-- desktop menu -->
				<div class="rx-mobile_menu position-relative">
					<div class="rx-mobile_menu_button rx-open_mobile_menu" (click)="toggleClass()">
						<i class="fas fa-bars"></i>
					</div>
					<div class="rx-mobile_menu_wrap" [ngClass]="{'mobile_menu_on': isClassActive}">
						<div class="mobile_menu_overlay rx-open_mobile_menu"></div>
						<div class="rx-mobile_menu_content">
							<div class="rx-mobile_menu_close rx-open_mobile_menu" (click)="toggleClass()">
								<i class="fas fa-times"></i>
							</div>
							<div class="m-brand-logo text-center">
								<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt=""></a>
							</div>
							<nav class="rx-mobile-main-navigation  clearfix ul-li">
								<ul id="m-main-nav" class="navbar-nav text-capitalize clearfix">
									<li><a class="nav-link" routerLink="/home">Home</a></li>
							<li><a class="nav-link " routerLink="/about">About</a></li>
							<li><a class="nav-link" routerLink="/service">Service</a></li>
							<li><a class="nav-link active" routerLink="/rewards">Reward on loan</a></li>
							<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
							<li><a class="nav-link" routerLink="/contact">Contact</a></li>
								</ul>
							</nav>
						</div>
					</div>
					<!-- /Mobile-Menu -->
				</div>
			</div>
		</div>
	</header>
<!-- End of header section
	============================================= -->
	
	
	<!-- <section class="saasio-breadcurmb-section">
		<div class="container">
			<div class="breadcurmb-title text-center">
				<h2>About</h2>
			</div>
			<div class="breadcurmb-item-list text-center ul-li">
				<ul class="saasio-page-breadcurmb">
					<li><a href="#">Reward</a></li>
				</ul>
			</div>
		</div>
	</section> -->

<!-- Start of about  section
	============================================= -->		
	<section id="rx-about" class="mt-5 pt-5 rx-about-section m-0 p-0">
		<div class="container">
			<div class="rx-about-content ">
				<div class="row">
					<div class="col-lg-4 wow fadeFromLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
						<div class="rx-about-award-img position-relative">
							<div class="rx-about-img-wrap">
								<img src="/assets/images/theme-images/reward.png" alt="">
							</div>
							
						</div>
					</div>
					<div class="col-lg-8 wow fadeFromRight " data-wow-delay="100ms" data-wow-duration="1000ms">
						<div class="rx-about-text h-100">
							<div class="rx-section-title headline d-flex flex-column justify-content-center lign-items-center h-100">
								<h2>
									Rewards are just a
									reference away! Start
									from  <span> ₹1000 to No Limit* </span>
								</h2>
					<a class="btn btn-primary text-white w-120" [routerLink]="['/loan-app']">Loan App</a>

							</div>
							
						</div>
					</div>
				</div>
			</div>


		</div>
	</section>

	<div class="container">
		<div class="rx-section-title headline text-center pb-5">
			<h2 class="color-blue">A Cycle of Limitless Rewards!
			</h2>
			<p>
				Refer, Earn and Watch Your rewards Multiply! Start earning rewards from Rs. 1000. The more you refer the MORE you get!
			</p>
		</div>
		
	</div>
<!-- End of about  section
	============================================= -->



<!-- Start of Footer section
	============================================= -->
	<footer  class="footer_section relative-position footer_section_4">
		<div class="background_overlay"></div>
		<div class="container">
			<div class="footer_content">
				<div class="row">
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								About Us
							</h3>
							<div class="widget_footer_text">
								At Loan Onn, we pride ourselves on being your one-stop destination for all loans.
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								Usefull Link

							</h3>
							<div class="practice_list ul-li-block clearfix">
								<ul>
									<li><a class="nav-link " routerLink="/home">Home</a></li>
							<li><a class="nav-link" routerLink="/about">About</a></li>
							<li><a class="nav-link" routerLink="/service">Service</a></li>
							<li><a class="nav-link active" routerLink="/rewards">Reward on loan</a></li>
							<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
							<li><a class="nav-link" routerLink="/contact">Contact</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								Contact Us
							</h3>
							<div >
								<ul>
									<li class="d-flex">
										<i class="fas fa-map-marker-alt mr-2"></i> 
										<a href="#"> Loanonn &#8482;<br> Infin Plus Pvt Ltd <br>
										27/12 J, 2nd Floor, A K S Tower
										Puthiyara Jn, Calicut
										Kerala – 673004 </a>
									</li>
									<li >
										<i class="fas fa-envelope mr-2"></i><a href="#">
											info&#64;investfinanceplus.com</a>
									</li>
									<li >
										<i class="fas fa-envelope mr-2"></i><a href="#">
											info&#64;loanonn.com</a>
									</li>
									<li>
										<i class="fas fa-phone mr-2"></i><a href="#">
											7403 111222</a>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>

	<div class="footer_copyright">
		<div class="container">
			<div class="footer_copyright_content">
				<div class="row">
					<div class="col-lg-4 col-md-12">
						<div class="footer_social ul-li clearfix">
							<ul>
								<li><a href="https://www.facebook.com/infinsmartadvise/"><i class="fab fa-facebook-f"></i></a></li>
								<li><a href="#"><i class="fab fa-twitter"></i></a></li>
								<li><a href="https://www.instagram.com/infinsmartadvise/"><i class="fab fa-instagram"></i></a></li>
															</ul>
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
							<div class="footer_logo text-center">
						<p class="text-white"><span>*</span>Terms & Conditions</p>
						<h6 class="text-white">The loans offered on the platform are subject to the terms and conditions and loan approval process of the  RBI-licensed BANKS AND NBFC partners</h6>
					</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="copyright_text text-right">
							Copyright By  <a href="https://hexabranding.com/">Hexa Branding</a> - 2024
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- End of Footer section
	============================================= -->


	<button class="qrCode">
		<p class="p1">Scan Now</p>
		<img class="img-fluid p-1" src="../../../assets/images/img/loanonnqr.png" alt="">
		<p class="p2">Get Reward</p>
	</button>