
	<!-- <div id="preloader"></div> -->
	<div class="up">
		<a href="#" class="scrollup text-center"><i class="fas fa-chevron-up"></i></a>
	</div>

<!-- Start of header section
	============================================= -->
	<header id="header_id" class="main_header header_style_four">
		<!-- <div class="header_top_content clearfix">
			<div class="container">
				<div class="header_top_text float-left">
					<span>Do you want to consult us? <a routerLink="contact">Find out more</a></span>
				</div>
				<div class="header_top_contact float-right">
					<a href="#"><i class="fas fa-phone"></i> 7403 111222</a>
					<a href="#"><i class="fas fa-map-marker-alt"></i> 27/12 J, 2nd Floor, A K S Tower ,  Calicut , Kerala </a>
					<a href="#"><i class="far fa-envelope"></i> info</a>
				</div>
			</div>
		</div> -->
		<div class="header_main_menu_wrap clearfix">
			<div class="container">
				<div class="brand_logo float-left">
					<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt="logo"></a>
				</div>
				<div class="rx_menu_item">
					<nav class="rx_main_navigation ul-li">
						<ul>
                            <ul>
								<li><a class="nav-link" routerLink="/home">Home</a></li>
								<li><a class="nav-link active" routerLink="about">About</a></li>
								<li><a class="nav-link" routerLink="/service">Service</a></li>
								<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
								<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
								<li><a class="nav-link" routerLink="/contact">Contact</a></li>
                            </ul>

						</ul>
					</nav>
					<div class="rx_header_btn text-center text-uppercase float-right">
						<a [routerLink]="['/loan-app']">Loan App</a>
					</div>
				</div>
				<!-- desktop menu -->
				<div class="rx-mobile_menu position-relative">
					<div class="rx-mobile_menu_button rx-open_mobile_menu" (click)="toggleClass()">
						<i class="fas fa-bars"></i>
					</div>
					<div class="rx-mobile_menu_wrap" [ngClass]="{'mobile_menu_on': isClassActive}">
						<div class="mobile_menu_overlay rx-open_mobile_menu"></div>
						<div class="rx-mobile_menu_content">
							<div class="rx-mobile_menu_close rx-open_mobile_menu" (click)="toggleClass()">
								<i class="fas fa-times"></i>
							</div>
							<div class="m-brand-logo text-center">
								<a [routerLink]="['/home']"><img src="assets/images/theme-images/loanon-logo.png" alt=""></a>
							</div>
							<nav class="rx-mobile-main-navigation  clearfix ul-li">
								<ul id="m-main-nav" class="navbar-nav text-capitalize clearfix">
									<li><a class="nav-link" routerLink="/home">Home</a></li>
							<li><a class="nav-link active" routerLink="/about">About</a></li>
							<li><a class="nav-link" routerLink="/service">Service</a></li>
							<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
							<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
							<li><a class="nav-link" routerLink="/contact">Contact</a></li>
								</ul>
							</nav>
						</div>
					</div>
					<!-- /Mobile-Menu -->
				</div>
			</div>
		</div>
	</header>
<!-- End of header section
	============================================= -->
	
<!-- 	
	<section class="saasio-breadcurmb-section">
		<div class="container">
			<div class="breadcurmb-title text-center">
				<h2>About</h2>
			</div>
			<div class="breadcurmb-item-list text-center ul-li">
				<ul class="saasio-page-breadcurmb">
					<li><a href="#">About</a></li>
				</ul>
			</div>
		</div>
	</section> -->

<!-- Start of about  section
	============================================= -->		
	<section id="rx-about" class="rx-about-section">
		<div class="container">
			<div class="rx-about-content">
				<div class="row align-items-center">
					<div class="col-lg-5 wow fadeFromLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
						<div class="rx-about-award-img position-relative">
							<div class="rx-about-img-wrap">
								<img src="/assets/images/img/w-01.png" alt="">
							</div>
							
						</div>
					</div>
					<div class="col-lg-7 wow fadeFromRight" data-wow-delay="100ms" data-wow-duration="1000ms">
						<div class="rx-about-text">
							<div class="rx-section-title headline">
								<h2>Welcome to Loan Onn -  <span>Your Financial Solutions Partner.</span>
								</h2>
							</div>
							<div class="rx-about-text-wrap ul-li-block pera-content">
								<p>LOAN ONN is  a platform that connects businesses with lending options offered by RBI-licensed BANKS AND NBFC partners.</p>
								<p> At Loan Onn, we take pride in being your one-stop destination for all loan products. Our mission is to help you compare and choose from a variety of financial solutions, tailored specifically to your needs. Our expert team conducts a comprehensive 360-degree analysis to provide you with the best-customized solutions. </p>
								
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>
	</section>

	<!-- <div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-6">
				<div class="rx-section-title headline text-center  pb-5">
					<h2 class="color-blue">What we do</h2>
					<p>LOAN ONN is  a platform that connects businesses with lending options offered by RBI-licensed BANKS AND NBFC partners</p>
					<p>Loanonn is your one-step destination for all your loans.
						We help you compare and choose from various financial products across .we indententify your requirement and compare available option in 360 degree analysis by our expert team to get customized end product that perfectly suited to your needs</p>
				</div>
			</div>
			<div class="col-sm-12 col-md-6">
				<div class="rx-section-title headline text-center pb-5">
					<h2 class="color-blue">Why Choose Loan Onn?
					</h2>
					<p>
						Personalized Service with No Additional Charges
		We believe in providing exceptional service without any hidden costs. At Loan Onn, there are no service fees or charges for any of our services. Our goal is to offer you the best financial products without adding to your expenses.
					</p>
				</div>
			</div>
		</div>
	</div> -->
	
<!-- End of about  section
	============================================= -->

<!-- Start of testimonial section
	============================================= -->
	<!-- <section class="rx-testimonial-section">
		<div class="container">
			<div class="rx-section-title headline text-center">
				<h2>See What Out Happy
					<span>Clients</span> Says
				</h2>
			</div>
			<div class="rx-testimonial-content">
				<div id="rx-testimonial-slide-id" class="rx-testimonial-slider">
					<div class="rx-testimonial-img-text">
						<div class="rx-testimonial-img float-left position-relative">
							<img src="assets/images/theme-images/tst1.jpg" alt="">
							<span class="rx-testi-quote"><i class="fas fa-quote-right"></i></span>
						</div>
						<div class="rx-testimonial-text headline pera-content">
							<div class="rx-testimonial-author">
								<h4><a href="#">Robert Frost</a></h4>
								<span>Managing Dirrector</span>
							</div>
							<p>Reach out not the long pole in my tent, but pull in ten extra bodies to help roll the tortoise, yet face time pipeline, for blue sky. creativity requires you to murder your children Cross sabers rehydrate.</p>
						</div>
					</div>
					<div class="rx-testimonial-img-text">
						<div class="rx-testimonial-img float-left position-relative">
							<img src="assets/images/theme-images/tst2.jpg" alt="">
							<span class="rx-testi-quote"><i class="fas fa-quote-right"></i></span>
						</div>
						<div class="rx-testimonial-text headline pera-content">
							<div class="rx-testimonial-author">
								<h4><a href="#">Nobilt Gilt</a></h4>
								<span>Consulting Manager</span>
							</div>
							<p>
								This is our north star design at the end of the day, for digital literacy. Good optics prethink. Green technology and climate change products need full resourcing and support from a cross-functional</p>
						</div>
					</div>
					<div class="rx-testimonial-img-text">
						<div class="rx-testimonial-img float-left position-relative">
							<img src="assets/images/theme-images/tst1.jpg" alt="">
							<span class="rx-testi-quote"><i class="fas fa-quote-right"></i></span>
						</div>
						<div class="rx-testimonial-text headline pera-content">
							<div class="rx-testimonial-author">
								<h4><a href="#">Robert Frost</a></h4>
								<span>SEO Expert</span>
							</div>
							<p>I recently worked with <span>easilon</span> for my home renovation project, and I couldn't be happier with the results. From the moment I walked into their showroom, I was impressed by the extensive selection</p>
						</div>
					</div>
					<div class="rx-testimonial-img-text">
						<div class="rx-testimonial-img float-left position-relative">
							<img src="images/tst2.jpg" alt="">
							<span class="rx-testi-quote"><i class="fas fa-quote-right"></i></span>
						</div>
						<div class="rx-testimonial-text headline pera-content">
							<div class="rx-testimonial-author">
								<h4><a href="#">Nobilt Gilt</a></h4>
								<span>Consulting Manager</span>
							</div>
							<p>I have zero cycles for this first-order optimal strategies. Closing these latest prospects is like putting socks on an octopus out of scope, for we need evergreen content What's our go to market strategy.</p>
						</div>	
					</div>
				</div>
			</div>
		</div>
	</section>			 -->
<!-- End of testimonial section
	============================================= -->

<!-- Start of Footer section
	============================================= -->
	<footer  class="footer_section relative-position footer_section_4">
		<div class="background_overlay"></div>
		<div class="container">
			<div class="footer_content">
				<div class="row">
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								About Us
							</h3>
							<div class="widget_footer_text">
								At Loan Onn, we pride ourselves on being your one-stop destination for all loans.
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								Usefull Link

							</h3>
							<div class="practice_list ul-li-block clearfix">
								<ul>
									<li><a class="nav-link active" routerLink="/home">Home</a></li>
							<li><a class="nav-link" routerLink="/about">About</a></li>
							<li><a class="nav-link" routerLink="/service">Service</a></li>
							<li><a class="nav-link" routerLink="/rewards">Reward on loan</a></li>
							<li><a class="nav-link" routerLink="/smart-save">Smart save on loan</a></li>
							<li><a class="nav-link" routerLink="/contact">Contact</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="footer_widget headline">
							<h3 class="widget_title">
								Contact Us
							</h3>
							<div >
								<ul>
									<li class="d-flex">
										<i class="fas fa-map-marker-alt mr-2"></i> 
										<a href="#"> Loanonn &#8482;<br> Infin Plus Pvt Ltd <br>
										27/12 J, 2nd Floor, A K S Tower
										Puthiyara Jn, Calicut
										Kerala – 673004 </a>
									</li>
									<li >
										<i class="fas fa-envelope mr-2"></i><a href="#">
											info&#64;investfinanceplus.com</a>
									</li>
									<li >
										<i class="fas fa-envelope mr-2"></i><a href="#">
											info&#64;loanonn.com</a>
									</li>
									<li>
										<i class="fas fa-phone mr-2"></i><a href="#">
											7403 111222</a>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>

	<div class="footer_copyright">
		<div class="container">
			<div class="footer_copyright_content">
				<div class="row">
					<div class="col-lg-4 col-md-12">
						<div class="footer_social ul-li clearfix">
							<ul>
								<li><a href="https://www.facebook.com/infinsmartadvise/"><i class="fab fa-facebook-f"></i></a></li>
								<li><a href="#"><i class="fab fa-twitter"></i></a></li>
								<li><a href="https://www.instagram.com/infinsmartadvise/"><i class="fab fa-instagram"></i></a></li>
								
								
							</ul>
						</div>
					</div>
					<div class="col-lg-4 col-md-12">
							<div class="footer_logo text-center">
						<p class="text-white"><span>*</span>Terms & Conditions</p>
						<h6 class="text-white">The loans offered on the platform are subject to the terms and conditions and loan approval process of the  RBI-licensed BANKS AND NBFC partners</h6>
					</div>
					</div>
					<div class="col-lg-4 col-md-12">
						<div class="copyright_text text-right">
							Copyright By  <a href="https://hexabranding.com/">Hexa Branding</a> - 2024
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
<!-- End of Footer section
	============================================= -->
